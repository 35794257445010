import { AnimatePresence, motion } from "framer-motion";
import { t } from "i18n:astro";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useBreakpoint } from "use-breakpoint";
import { ExpandableId } from "../../../../../data/expandable";
import { useAppStore } from "../../../../../hooks/useAppStore";
import { mediaBreakpointsConfig } from "../../../../../types/breakpoints";
import { scrollToExpandable } from "../../../../../utils/scrollToExpandable";
import type { TeamObject } from "../../_types";
import TeamDetails from "../TeamDetails/TeamDetails";
import TeamGrid from "../TeamGrid/TeamGrid";
import "./style.scss";

const Team = () => {
  const isExpanded = useAppStore((s) => s.expandables[ExpandableId.ABOUT_2]);
  const [selected, setSelected] = useState<number | undefined>(undefined);
  const { breakpoint } = useBreakpoint(mediaBreakpointsConfig);

  const data = useMemo(() => {
    const basePath = (val: string) => `/lp_team/${val}`;
    let res: TeamObject[] = [
      {
        id: 1,
        name: t("home:mainExpand.team.members.samuel-hieber.name"),
        position: t("home:mainExpand.team.members.samuel-hieber.position"),
        description: t("home:mainExpand.team.members.samuel-hieber.description"),
        photo: basePath("samuel.jpg"),
        linkedIn: "https://www.linkedin.com/in/samuelhieber",
        order: 1,
      },
      {
        id: 2,
        name: t("home:mainExpand.team.members.patrick-thoma.name"),
        position: t("home:mainExpand.team.members.patrick-thoma.position"),
        description: t("home:mainExpand.team.members.patrick-thoma.description"),
        photo: basePath("patrick.jpg"),
        linkedIn: "https://www.linkedin.com/in/contactpatrickthoma",
        order: 2,
      },
      {
        id: 3,
        name: t("home:mainExpand.team.members.mateusz-muszynski.name"),
        position: t("home:mainExpand.team.members.mateusz-muszynski.position"),
        description: t("home:mainExpand.team.members.mateusz-muszynski.description"),
        photo: basePath("mateusz.jpg"),
        linkedIn: "https://www.linkedin.com/in/mateusz-muszynski-821624130",
        order: 3,
      },
      {
        id: 4,
        name: t("home:mainExpand.team.members.irina-tigal.name"),
        position: t("home:mainExpand.team.members.irina-tigal.position"),
        description: t("home:mainExpand.team.members.irina-tigal.description"),
        photo: basePath("irina.jpg"),
        linkedIn: "https://www.linkedin.com/in/irina-tigal-33b76683",
        order: 4,
      },
      {
        id: 5,
        name: t("home:mainExpand.team.members.kacper-wisniewski.name"),
        position: t("home:mainExpand.team.members.kacper-wisniewski.position"),
        description: t("home:mainExpand.team.members.kacper-wisniewski.description"),
        photo: basePath("kacper.jpg"),
        linkedIn: "https://www.linkedin.com/in/kacperwisniewski",
        order: 5,
      },
      {
        id: 6,
        name: t("home:mainExpand.team.members.grace-ekele.name"),
        position: t("home:mainExpand.team.members.grace-ekele.position"),
        description: t("home:mainExpand.team.members.grace-ekele.description"),
        photo: basePath("grace.jpg"),
        linkedIn: "https://www.linkedin.com/in/grace-ekele-175a1a97/",
        order: 6,
      },
      {
        id: 7,
        name: t("home:mainExpand.team.members.piotr-kozłowski.name"),
        position: t("home:mainExpand.team.members.piotr-kozłowski.position"),
        description: t("home:mainExpand.team.members.piotr-kozłowski.description"),
        photo: basePath("piotr.jpg"),
        linkedIn: "https://www.linkedin.com/in/piotr-koz%C5%82owski-b437623",
        order: 7,
      },
      {
        id: 8,
        name: t("home:mainExpand.team.members.zuza-henshaw.name"),
        position: t("home:mainExpand.team.members.zuza-henshaw.position"),
        description: t("home:mainExpand.team.members.zuza-henshaw.description"),
        photo: basePath("zuza.jpg"),
        linkedIn: "https://www.linkedin.com/in/zuzanna-henshaw",
        order: 8,
      },
    ];
    res = res.sort((a, b) => a.order - b.order);
    return res;
  }, [t]);

  const selectedData = useMemo(
    () =>
      selected !== undefined ? data.find((item) => item.id === selected) : undefined,
    [data, selected],
  );

  const handleSelect = useCallback(
    (id: number | undefined) => {
      setSelected(id);
      if (breakpoint === "mobile") {
        scrollToExpandable(ExpandableId.ABOUT_2);
      }
    },
    [breakpoint],
  );

  useEffect(() => {
    if (!isExpanded) {
      const timeoutId = setTimeout(() => {
        setSelected(undefined);
      }, 210);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isExpanded, setSelected]);

  return (
    <motion.div className="team-container">
      <AnimatePresence mode="wait" key={selected}>
        {selectedData === undefined && <TeamGrid data={data} onSelect={handleSelect} />}
        {selectedData !== undefined && (
          <TeamDetails data={data} onSelect={handleSelect} selected={selectedData} />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Team;
